import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/page/redirect/index.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/page/login/index.vue")
  },
  {
    path: "/login/:lang",
    name: "Login-language",
    component: () => import("@/views/page/login/index.vue")
  },
   {
    path: "login/kerryfriend",
    name: "login-kerryfriend",
    component: () => import("@/views/page/login/indexFriend.vue")
  },
  {
    path: "/login/kerryfriend/:lang",
    name: "login-kerryfriend-language",
    component: () => import("@/views/page/login/indexFriend.vue")
  },
  {
    path: "login/kerryfriend",
    name: "login-kerryfriend",
    component: () => import("@/views/page/login/indexFriend.vue")
  },
  {
    path: "/login/kerryfriend/:lang",
    name: "login-kerryfriend-language",
    component: () => import("@/views/page/login/indexFriend.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/page/register/index.vue")
  },
  {
    path: "/register/:lang",
    name: "Register-language",
    component: () => import("@/views/page/register/index.vue")
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/views/page/forget-password/index.vue")
  },
  {
    path: "/forget-password/:lang",
    name: "forget-password-language",
    component: () => import("@/views/page/forget-password/index.vue")
  },
  {
    path: "/complain",
    name: "complain",
    component: () => import("@/views/page/complain/index.vue")
  },
  {
    path: "/complain/:lang",
    name: "complain-language",
    component: () => import("@/views/page/complain/index.vue")
  },
  {
    path: "/complain/kerryfriend",
    name: "complain-kerryfriend",
    component: () => import("@/views/page/complain/indexFriend.vue")
  },
  {
    path: "/complain/kerryfriend/:lang",
    name: "complain-kerryfriend-language",
    component: () => import("@/views/page/complain/indexFriend.vue")
  },
  {
    path: "/complain/kerryfriend",
    name: "complain-kerryfriend",
    component: () => import("@/views/page/complain/indexFriend.vue")
  },
  {
    path: "/complain/kerryfriend/:lang",
    name: "complain-kerryfriend-language",
    component: () => import("@/views/page/complain/indexFriend.vue")
  },
  {
    path: "/service-location",
    name: "service-location",
    component: () => import("@/views/page/service-location/index.vue")
  },
  {
    path: "/service-location/:lang",
    name: "service-location-language",
    component: () => import("@/views/page/service-location/index.vue")
  },
  {
    path: "/term-and-condition",
    name: "term-and-condition",
    component: () => import("@/views/page/term-and-condition/index.vue")
  },
  {
    path: "/create-shipment",
    name: "CreateShipment",
    component: () => import("@/views/page/create-shipment/index.vue")
  },
  {
    path: "/create-shipment/:lang",
    name: "CreateShipment-language",
    component: () => import("@/views/page/create-shipment/index.vue")
  },
  {
    path: "/show-price",
    name: "ShowPrice",
    component: () => import("@/views/page/show-price/index.vue")
  },
  {
    path: "/show-price/:lang",
    name: "ShowPrice-language",
    component: () => import("@/views/page/show-price/index.vue")
  },
  {
    path: "/my-booking",
    name: "MyBookingHistory",
    component: () => import("@/views/page/my-booking/index.vue")
  },
  {
    path: "/my-booking/:lang",
    name: "MyBookingHistory-language",
    component: () => import("@/views/page/my-booking/index.vue")
  },
  {
    path: "/my-booking/details",
    name: "MyBookingDetail",
    component: () => import("@/views/page/my-booking/detail.vue")
  },
  {
    path: "/my-booking/details/:lang",
    name: "MyBookingDetail-language",
    component: () => import("@/views/page/my-booking/detail.vue")
  },
  {
    path: "/my-shipment",
    name: "MyShipmentHistory",
    component: () => import("@/views/page/my-shipment/index.vue")
  },
  {
    path: "/my-shipment/:lang",
    name: "MyShipmentHistory-language",
    component: () => import("@/views/page/my-shipment/index.vue")
  },
  {
    path: "/my-shipment/details",
    name: "MyShipmentDetail",
    component: () => import("@/views/page/my-shipment/detail.vue")
  },
  {
    path: "/my-shipment/details/:lang",
    name: "MyShipmentDetail-language",
    component: () => import("@/views/page/my-shipment/detail.vue")
  },
  {
    path: "/session-expired",
    name: "session-expired",
    component: () => import("@/views/page/error/session-expired.vue")
  },
  {
    path: "/success/:action",
    name: "success",
    component: () => import("@/views/page/landing/index.vue")
  },
  {
    path: "/queue",
    name: "queue",
    component: () => import("@/views/page/queue/index.vue")
  },
  {
    path: "/queue/:lang",
    name: "queue-language",
    component: () => import("@/views/page/queue/index.vue")
  },
  {
    path: "/audit",
    name: "audit",
    component: () => import("@/views/page/audit/index.vue")
  },
  {
    path: "/audit/:lang",
    name: "audit-language",
    component: () => import("@/views/page/audit/index.vue")
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
